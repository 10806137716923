import React, { useEffect, useRef, useState } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Axios from "axios";
import { Helmet } from 'react-helmet';
import _ from 'lodash';

export default function LandingPage({ data }) {
  const homePageData = data;

  const property = useRef();
  const luxuryService = useRef();
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState('');

  const header_descriptor = homePageData?.allContentfulDescriptor?.nodes.find((item) => item?.contentful_id === '4LMbM7tdOQofMqq9RgeNNW')
  const bottom_descriptor = homePageData?.allContentfulDescriptor?.nodes.find((item) => item?.contentful_id === '5LXGX10caBFaXZuM2PJEr0')

  const blogs = homePageData?.allContentfulBlog?.nodes;
  const oderedBlogData = _.orderBy(blogs, [blogs => blogs.title], ['asc'])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const connectNowHandler = (event) => {
    event.preventDefault();
    if (email) {
      const body = {
        "email": email
      };

      Axios.post("https://api.lqam.com/users/send-subscribe-user-request", body)
        .then(response => {
          if (response.data.statusCode === 200) {
            setEmailResponse('Your request has been sent successfully');
          }
        }).catch(err => {
        })
    }
  };

  return (
    <>
      <Helmet>
        <title>LQAM Come Home to Luxury</title>
      </Helmet>
      <BackgroundImage
        className="banner"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={homePageData?.contentfulHeroHeader?.headerImage?.fluid}
      >
        <div className="container">
          <div className="banner-wrapper">
            <h1 className="heading">
              {homePageData?.contentfulHeroHeader?.headerTitle}
            </h1>
          </div>
        </div>
      </BackgroundImage>

      {/* </section> */}
      <section className="come-home">
        <div className="container">
          <div className="come-home-wrapper">
            <div className="title-wrapper text-left">
              <h2 className="theme-title text-center">
                {header_descriptor &&
                  header_descriptor?.title}
              </h2>
              <p className="theme-desp text-center">
                {header_descriptor &&
                  header_descriptor?.description}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Property section - image and descriptor */}
      <section className="about blog-posts" ref={property}>
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              {oderedBlogData &&
                oderedBlogData?.length > 0 &&
                oderedBlogData?.map(
                  (item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-md-6 about-col" id="property">
                          <Link to={`/${item?.redirectLink}/`}>
                            <span className="img">
                              <Img fluid={item?.image?.fluid} alt="Property Care" />
                            </span>
                            <div className="title-wrapper">
                              <h2 className="theme-title">{item?.title}</h2>
                              <p className="theme-desp">{item?.description}</p>
                            </div>
                            <Link to={`/${item?.redirectLink}/`} className="more-link">
                              Learn More
                            </Link>
                          </Link>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </section>
      <section className="services" ref={luxuryService}>
        <div className="container">
          <div className="services-wrapper">
            {/* bottom descriptor */}
            <div className="title-wrapper">
              <h2 className="theme-title text-center">
                {bottom_descriptor?.title}
              </h2>
              <p className="theme-desp text-center">
                {bottom_descriptor?.description}
              </p>
            </div>

            {/* post section */}
            {homePageData?.allContentfulPosts &&
              homePageData?.allContentfulPosts?.nodes?.length > 0 ? (
              <div className="service-list">
                <div
                  className="row"
                  style={{ justifyContent: "space-between" }}
                >
                  {homePageData?.allContentfulPosts?.nodes.map(
                    (posts) => {
                      return (
                        <div className="col-md-3 service-col" key={posts.id}>
                          <Link to={`/${posts.page}/`} state={{ scrollTo: posts.scrollTo }}>
                            <span className="img">
                              <Img
                                fluid={posts.image.fluid}
                                alt="White Glove Concierge"
                              />
                            </span>
                            <h4>{posts?.title}</h4>
                            <Link className="more-link">
                              Learn More
                            </Link>
                          </Link>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {/* Connect section */}
      <section className="connect-with">
        <div className="container">
          <div className="connect-with-wrapper">
            <div className="title-wrapper">
              <h2 className="theme-title text-center">
                {homePageData?.contentfulConnectHeaders?.title}
              </h2>
            </div>
            <form className="connect-from" onSubmit={(e) => connectNowHandler(e)}>
              <div className="form-field">
                <input
                  type="text"
                  placeholder="Add your email here "
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {
                  emailResponse ? <p style={{ marginTop: 4 }}>{emailResponse}</p> : null
                }
              </div>
              <div className="submit-btn">
                <input
                  type="submit"
                  className="theme-button"
                  value={"CONNECT NOW"}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
