import React from "react";
import { graphql } from "gatsby";
import LandingPage from "../components/landing-page";

const IndexPage = ({ data }) => {
  return (
    <>
      <LandingPage data={data} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    allContentfulNavigationSliderContent {
    nodes{
    	title{
         title
      }
      redirectUrl,
      link
    }
  }
    contentfulDescriptor(contentful_id: {eq: "5uW1H6PYfaDX2mJZHzeJR"}) {
    title
    description
  }
    contentfulHeroHeader(contentful_id: {eq: "wGD54uu3OA57AtanpRqDu"}) {
    headerTitle
    headerImage {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }

  allContentfulDescriptor(
    filter: {contentful_id: {in: ["4LMbM7tdOQofMqq9RgeNNW", "5LXGX10caBFaXZuM2PJEr0"]}}
  ) {
    nodes {
      description
      title
      contentful_id
    }
  }

  contentfulConnectHeaders(contentful_id: {eq: "6TcuWyubRt7iKIgbZcGUZf"}) {
    title
  }

   allContentfulBlog(
        filter: {contentful_id: {in: ["4k8zebCNLBqASLqKBEtARg","3xkX7hB8J1XIyX1DowdTrs"]}}
      ) {
        nodes {
          title
          redirectLink
          description
          contentful_id
          image {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
  }

    allContentfulPosts(limit: 4) {
    nodes {
      id
      title
      image {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      page
      scrollTo
    }
  }
    contentfulCookie {
    cookieText {
      cookieText
    }
  }
  }
`;
